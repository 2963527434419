import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Header from '../components/layout/header/header'
import Footer from '../components/layout/footer/footer'
import { HandleTracking } from '../helpers/handleTracking'

declare global {
    interface Window {
        dataLayer: any
    }
}
function Main() {
    HandleTracking()

    return (
        <div className="main">
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}

export default Main
