import pageStyles from '../../globals/pages.module.scss'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { SubmitButton, Wrapper } from '../reusable'
import star from '../../assets/star.svg'

function ThreePointDiv() {
    useEffect(() => {}, [])
    const navigate = useNavigate()
    return (
        <div className={`${styles.threePointDiv} ${responsiveStyles.threePointDiv}`}>
            <Wrapper>
                <div className={`${styles.threePointDivInner} ${responsiveStyles.threePointDivInner}`}>
                    <div>
                        <img src={star} />
                        <p>Claim up to £10,000 compensation</p>
                    </div>
                    <div>
                        <img src={star} />
                        <p>FREE no obligation quote</p>
                    </div>
                    <div>
                        <img src={star} />
                        <p>Get your damages fixed hassle free!</p>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export default ThreePointDiv
