import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ScrollToTop } from './components/layout/scrollTop/scrollToTop'

import Main from './containers/main'
import Home from './containers/home'
import ClaimForm from './containers/form'
import ReEngageForm from './containers/re-engage'

export default function AppRouter() {
    // Router
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route index element={<Home />} />
                    <Route path="start" element={<ClaimForm />} />
                    <Route path="engage" element={<ReEngageForm />} />
                </Route>
            </Routes>
        </Router>
    )
}
