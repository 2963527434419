import pageStyles from '../../globals/pages.module.scss'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { SubmitButton, Wrapper } from '../reusable'
import img1 from '../../assets/img1.jpg'
import img2 from '../../assets/repair.jpg'

const contentOptions = [
    {
        title: 'Claim between £3,000 - £18,000+',
        text: '<p>Our main goal is to ensure that you the client have an excellent legal experience, that your property is repaired and that you recover the maximum compensation you are entitled to in the most convenient of timescales.</p>',
        img: img2,
    },
    {
        title: 'What repairs can we help you with?',
        text: `<p>If you are suffering from any of the following issues in your rental property, we can help you get any necessary repairs completed and receive compensation.</p>
            <ul>
                <li>Damp & Mould</li>
                <li>Dry rot</li>
                <li>Rodent Infestation</li>
                <li>Structural problems</li>
                <li>Rotten windows</li>
                <li>Water damage</li>
                <li>Central Heating</li>
            </ul>`,
        img: img1,
    },
]

function TwoCol({ contentSelect, contentFlip }: any) {
    useEffect(() => {}, [])
    const navigate = useNavigate()
    return (
        <div className={`${styles.twoCols} ${responsiveStyles.twoCols}`}>
            <Wrapper>
                <div
                    className={`${styles.twoColsInner} ${responsiveStyles.twoColsInner} ${contentFlip ? styles.flip : ''} ${contentFlip ? responsiveStyles.flip : ''}`}
                >
                    <div>
                        <h3>{contentOptions[contentSelect].title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: contentOptions[contentSelect].text }}></div>
                    </div>
                    <div>
                        <img src={contentOptions[contentSelect].img} />
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export default TwoCol
