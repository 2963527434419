import pageStyles from '../../globals/pages.module.scss'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { SubmitButton, Wrapper } from '../reusable'
import img2 from '../../assets/img2.jpg'
import img3 from '../../assets/img3.jpg'
import img4 from '../../assets/img4.jpg'

function HowItWorks() {
    useEffect(() => {}, [])
    const navigate = useNavigate()
    return (
        <div className={`${styles.howItWorks} ${responsiveStyles.howItWorks}`}>
            <Wrapper>
                <div className={`${styles.howItWorksInner} ${responsiveStyles.howItWorksInner}`}>
                    <h3>How it works - it takes 60 seconds</h3>
                    <div className={`${styles.steps} ${responsiveStyles.steps}`}>
                        <div>
                            <img src={img2} />
                            <p>Fill out 30 seconds form</p>
                        </div>
                        <div>
                            <img src={img3} />
                            <p>Get your home surveyed</p>
                        </div>
                        <div>
                            <img src={img4} />
                            <p>Receive your compensation</p>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export default HowItWorks
