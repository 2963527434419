import styles from '../globals/pages.module.scss'
import pageStyles from './styles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import MainForm from '../components/form'

function ReEngageForm() {
    useEffect(() => {}, [])

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            {/* <iframe /> */}
            <h1 style={{ padding: '0', textAlign: 'center', marginTop: '40px' }}>Complete Your Application</h1>
            <iframe
                src="https://link.contactfusion.co.uk/widget/survey/7xpbutNO2TwjlrKvuzvp"
                style={{ border: 'none', width: '100%', minHeight: '1200px' }}
                id="7xpbutNO2TwjlrKvuzvp"
                title="HDR Claim Form"
            ></iframe>
            {/* <script src="https://link.contactfusion.co.uk/js/form_embed.js"></script> */}
        </div>
    )
}

export default ReEngageForm
