import styles from '../globals/pages.module.scss'
import pageStyles from './styles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import MainForm from '../components/form'

function ClaimForm() {
    useEffect(() => {}, [])

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <MainForm />
        </div>
    )
}

export default ClaimForm
