import AppRouter from './router'

function App() {
    return (
        <div className="App">
            <AppRouter></AppRouter>
        </div>
    )
}

export default App
