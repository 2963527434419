import styles from './styles.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import logo from '../../../assets/logo.svg'
import { Wrapper } from '../../reusable'

function Footer() {
    return (
        <div className={`${styles.footer} ${responsiveStyles.footer}`}>
            <Wrapper>
                <div className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}>
                    <img src={logo} />
                    <p className={styles.disclaimer}>
                        tenantclaimsuk.org is a trading style of Sentinel Legal Limited. <br /> <br />
                        Sentinel legal Limited is Registered in England and Wales. Registered office Harley house, 29
                        Cambray Place, Cheltenham, England, GL50 1JN. Company number 12826774. VAT registration number
                        423 9685 69 Authorised and regulated by the Solicitors Regulation Authority. SRA number 811792.
                    </p>

                    <a target="__blank" href="https://www.sentinellegal.co.uk/privacy-policy">
                        Privacy Notice
                    </a>
                </div>
            </Wrapper>
            <div className={`${styles.footerBottom} ${responsiveStyles.footerBottom}`}>
                <Wrapper>
                    <p>
                        <span>Sentinel Legal (c) {new Date().getFullYear()}</span>
                    </p>
                </Wrapper>
            </div>
        </div>
    )
}

export default Footer
