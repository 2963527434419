import pageStyles from '../../globals/pages.module.scss'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { SubmitButton, Wrapper } from '../reusable'
import mould from '../../assets/examples/mould2.jpg'
import waterDamage from '../../assets/examples/water-damage.jpg'
import windows from '../../assets/examples/windows.jpg'
import damp from '../../assets/examples/damp.jpg'
import dryRot from '../../assets/examples/dryrot.jpg'
import heating from '../../assets/examples/heating.jpg'
// import img1 from '../../assets/img1.jpg'
// import img1 from '../../assets/img1.jpg'

function Examples() {
    useEffect(() => {}, [])
    const navigate = useNavigate()
    return (
        <div className={`${styles.examples} ${responsiveStyles.examples}`}>
            <Wrapper>
                <div className={`${styles.examplesInner} ${responsiveStyles.examplesInner}`}>
                    <h3>Examples of Damage Found</h3>
                    <div className={`${styles.exampleImages} ${responsiveStyles.exampleImages}`}>
                        <div>
                            <img src={windows} />
                            <p>Rotten Windows</p>
                        </div>
                        <div>
                            <img src={waterDamage} />
                            <p>Water Damage</p>
                        </div>
                        <div>
                            <img src={mould} />
                            <p>Mould</p>
                        </div>
                        <div>
                            <img src={dryRot} />
                            <p>Dry Rot</p>
                        </div>
                        <div>
                            <img src={heating} />
                            <p>Central Heating</p>
                        </div>
                        <div>
                            <img src={damp} />
                            <p>Damp Spots</p>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export default Examples
