import axios from 'axios'
interface bodyInt {
    [key: string]: any
    // options?: any
}

export async function Data8Service(serviceType: string, service: string, body: bodyInt) {
    // console.log('body', body);
    try {
        let axiosData = {
            method: 'POST',
            headers: { 'content-type': 'application/json; charset=utf-8' },
            url: `https://webservices.data-8.co.uk/${serviceType}/${service}.json?key=${process.env.REACT_APP_DATA8_API_KEY}`,
            timeout: 180000, //optional
            data: body,
        }

        const response = await axios(axiosData)
        // console.log("DATA8 NETWORK RESPONSE: ", response.status === 200);
        return response.data
    } catch (error) {
        return error
    }
}
