import styles from '../globals/pages.module.scss'
import pageStyles from './styles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import Hero from '../components/hero'
import ThreePointDiv from '../components/threePointDiv'
import TwoCol from '../components/twoCol'
import Examples from '../components/examples'
import TwoColForm from '../components/twoColForm'
import HowItWorks from '../components/howItworks'

function Home() {
    useEffect(() => {}, [])
    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <Hero />
            <ThreePointDiv />
            <TwoCol contentFlip={false} contentSelect={0} />
            <Examples />
            <TwoColForm contentSelection={0} />
            <HowItWorks />
            <TwoCol contentFlip={true} contentSelect={1} />
            <TwoColForm contentSelection={1} />
        </div>
    )
}

export default Home
