import pageStyles from '../../globals/pages.module.scss'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { SubmitButton, SubmitDiv } from '../reusable'
import { InputFieldText } from '../reusable/inputFieldText'
import { trackCompleteRegistration } from '../../helpers'

function MainForm() {
    const [dataToSave, setDataToSave] = useState<any>({})
    const formRef = useRef(null)
    useEffect(() => {}, [])
    const navigate = useNavigate()

    const save = (obj: { field: string; value: string }) => {
        let tempData: any = { ...dataToSave }
        tempData[obj.field] = obj.value
        setDataToSave(tempData)
        return { success: true, error: '' }
    }

    const checkAutoFill = (checkDataToSave: any) => {
        const webform: any = formRef.current
        let formDataToSave: any = {}
        Object.values(webform).forEach((elem: any) => {
            if (['text', 'tel', 'email'].some((t) => t == elem.type)) formDataToSave[elem.name] = elem.value
        })
        checkDataToSave = { ...checkDataToSave, ...formDataToSave }
        return checkDataToSave
    }

    const handleSubmit = async () => {
        // console.log('HANDLE SUBMISSION....', dataToSave)
        Loading.standard('This may take a minute.')

        try {
            const validate = [
                'firstname',
                'lastname',
                'email',
                'phone',
                'landlord',
                'arrears',
                'longtermTennant',
                'disrepair',
                'claimed',
                'reported',
            ]
            let checkDataToSave = checkAutoFill({ ...dataToSave })

            validate.forEach((check: string) => {
                if (!checkDataToSave[check] || !checkDataToSave[check].length)
                    throw new Error(`Missing required field - ${check}`)
            })

            const response = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_UPLOAD_DATA_URL}`,
                data: checkDataToSave,
                // headers: { "Content-Type": "multipart/form-data" },
            })

            Loading.remove()
            console.log('DATA UPLOAD RESPONSE: ', response.data)
            if (response.data.success) {
                Report.success('Claim Accepted', 'Thank you, we have received your claim.', 'Okay', () => navigate('/'))
                // trackLead({ email: dataToSave.email, phone: dataToSave.phone })
                trackCompleteRegistration()
            } else {
                throw new Error(`Duplicate`)
            }
        } catch (error: any) {
            Loading.remove()
            console.log(error.message)
            if (error.message.includes('Missing required field')) {
                Report.failure('Required Fields', error.message, 'okay')
            } else if (error.message == 'Duplicate') {
                Report.failure('Duplicate Claim', 'We have already receive your claim.', 'okay')
            } else {
                console.log('ERROR IS : ', error)
                Report.failure('Error Ocurred', 'Please refresh the page and try again.', 'okay')
            }
        }
    }

    const defaultSubmit = (e: any) => {
        e.preventDefault()
    }

    const handleChange = (e: any) => {
        console.log(e.target.name)
        console.log(e.target.value)

        let tempData: any = { ...dataToSave }
        tempData[e.target.name] = e.target.value
        setDataToSave(tempData)
    }

    return (
        <div className={`${styles.mainForm} ${responsiveStyles.mainForm}`}>
            <div className={`${styles.mainFormContent} ${responsiveStyles.mainFormContent}`}>
                <div className={`${styles.formContainer} ${responsiveStyles.formContainer}`}>
                    <h1>Simply Complete The Form To Qualify</h1>
                    <p>All fields marked with an * are required</p>
                    <form onSubmit={(e) => defaultSubmit(e)} ref={formRef}>
                        <fieldset>
                            <label>Who do you rent your property from?*</label>
                            <select name={'landlord'} onChange={(e) => handleChange(e)}>
                                <option>Please Select</option>
                                <option value={'council'}>Council</option>
                                <option value={'housing association'}>Housing Association</option>
                                <option value={'private'}>Private</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <label>Do you owe more than £1000 in rent arrears?*</label>
                            <select name={'arrears'} onChange={(e) => handleChange(e)}>
                                <option>Please Select</option>
                                <option value={'yes'}>yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <label>Have you live in the property for more than 6 months?*</label>
                            <select name={'longtermTennant'} onChange={(e) => handleChange(e)}>
                                <option>Please Select</option>
                                <option value={'yes'}>yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <label>What disrepair issue does your home have?*</label>
                            <select name={'disrepair'} onChange={(e) => handleChange(e)}>
                                <option>Please Select</option>
                                <option value={'mould'}>Mould</option>
                                <option value={'brickwork'}>Brickwork</option>
                                <option value={'windows'}>Windows</option>
                                <option value={'cracks'}>Cracks</option>
                                <option value={'pest'}>Pest</option>
                                <option value={'damp'}>Damp</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <label>Have you reported the issues to the landlord?*</label>
                            <select name={'reported'} onChange={(e) => handleChange(e)}>
                                <option>Please Select</option>
                                <option value={'yes'}>yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <label>Have you made any housing disrepair claims relating to the issue already?*</label>
                            <select name={'claimed'} onChange={(e) => handleChange(e)}>
                                <option>Please Select</option>
                                <option value={'yes'}>yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </fieldset>
                        <h2>Please enter your details to find out your claim valuation.</h2>
                        <input
                            type="text"
                            name="firstname"
                            placeholder={'First Name*'}
                            onChange={(e) => handleChange(e)}
                        />
                        <input
                            type="text"
                            name="lastname"
                            placeholder={'Last Name*'}
                            onChange={(e) => handleChange(e)}
                        />
                        <InputFieldText fieldName="email" fieldType={'email'} saveValue={save} placeholder={'Email*'} />
                        <InputFieldText
                            fieldName="phone"
                            fieldType={'tel'}
                            saveValue={save}
                            placeholder={'Phone Number*'}
                        />
                        <input
                            type="text"
                            name="postcode"
                            placeholder={'Postcode*'}
                            onChange={(e) => handleChange(e)}
                        />
                        <SubmitDiv text="CHECK" onClick={() => handleSubmit()} btnType="primary" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MainForm
