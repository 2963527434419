import pageStyles from '../../globals/pages.module.scss'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper } from '../reusable'
import arrowRight from '../../assets/arrow-right.svg'
const contentOptions = [
    {
        title: 'Who can I claim against?',
        text: `You can make a claim against <b>any council or housing association landlord</b> if you reported damage or disrepair to them and it wasn't fixed quickly.<br /><br/>Listed here are some of the larger UK-based housing associations, however this is not an exhaustive list. If you rent your home from any council or housing association in the UK, please get in touch with us as you could be owed compensation.`,
    },
    {
        title: 'How do I know if I can claim?',
        text: `As a tenant, you must have informed your landlord of the disrepair before a case can be brought against them. A housing disrepair claim can be brought by you, the tenant, against a landlord if they have failed to act once you’ve notified them of the disrepair and it has posed a potential danger to your health and safety.<br /><br/>Landlords have a duty of care to tenants, and the law requires them to keep accommodation in an adequate state of repair and ensure it meets health and safety standards. You can make a housing disrepair claim against your landlord when they have failed to meet these standards.`,
    },
]
function TwoColForm({ contentSelection }: any) {
    useEffect(() => {
        const item = contentOptions[contentSelection]
    }, [contentSelection])
    const navigate = useNavigate()
    return (
        <div className={`${styles.twoCols} ${responsiveStyles.twoCols}`}>
            <Wrapper>
                <div className={`${styles.twoColsInner} ${responsiveStyles.twoColsInner}`}>
                    <div className={`${styles.colContent} ${responsiveStyles.colContent}`}>
                        {contentSelection != null ? (
                            <>
                                <h3>{contentOptions[contentSelection].title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: contentOptions[contentSelection].text }}></p>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className={`${styles.colForm} ${responsiveStyles.colForm}`}>
                        <div>
                            <form>
                                <p>CHECK MY ELIGIBILITY</p>
                                <input type="text" name="postcode" placeholder="Enter Your Postcode" />
                                <button
                                    onClick={() => {
                                        navigate('/start')
                                    }}
                                >
                                    CHECK <img src={arrowRight} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export default TwoColForm
