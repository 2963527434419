import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitButton } from '../reusable'
import arrowRight from '../../assets/arrow-right.svg'

function Hero() {
    useEffect(() => {}, [])
    const navigate = useNavigate()
    return (
        <div className={`${styles.hero} ${responsiveStyles.hero}`}>
            <div className={`${styles.heroContent} ${responsiveStyles.heroContent}`}>
                <h1>Are You Struggling With A Poorly Maintained Property?</h1>
                <p>
                    Fill out the form below for a call back as soon as possible
                    <br /> You could be able to claim up to <span>£10,000!</span>
                </p>{' '}
                <SubmitButton
                    text={`Check Your Eligibility`}
                    btnType="secondary"
                    onClick={() => {
                        navigate('start')
                    }}
                />
                <div className={`${styles.heroForm} ${responsiveStyles.heroForm}`}>
                    <form>
                        <fieldset>
                            <input type={'text'} name="postcode" placeholder={'Enter Your Postcode'} />
                            <button
                                onClick={() => {
                                    navigate('/start')
                                }}
                            >
                                CHECK <img src={arrowRight} alt={'arrow'} />
                            </button>
                        </fieldset>
                    </form>
                    <p>
                        <img /> By fully or partially completing this form you agree to the privacy policy and terms &
                        conditions of our website
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Hero
